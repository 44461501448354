/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum Borough {
  BRONX = "BRONX",
  BROOKLYN = "BROOKLYN",
  MANHATTAN = "MANHATTAN",
  QUEENS = "QUEENS",
  STATEN_ISLAND = "STATEN_ISLAND",
}

/**
 * An enumeration.
 */
export enum CustomIssueArea {
  BATHROOMS = "BATHROOMS",
  BEDROOMS = "BEDROOMS",
  HOME = "HOME",
  KITCHEN = "KITCHEN",
  LANDLORD = "LANDLORD",
  LIVING_ROOM = "LIVING_ROOM",
  PUBLIC_AREAS = "PUBLIC_AREAS",
}

/**
 * An enumeration.
 */
export enum HPDocusignStatus {
  DECLINED = "DECLINED",
  IN_PROGRESS = "IN_PROGRESS",
  SIGNED = "SIGNED",
}

/**
 * The status of the HP Action upload (document assembly) process for a user.
 */
export enum HPUploadStatus {
  ERRORED = "ERRORED",
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
  SUCCEEDED = "SUCCEEDED",
}

/**
 * An enumeration.
 */
export enum HabitabilityLetterMailChoice {
  USER_WILL_MAIL = "USER_WILL_MAIL",
  WE_WILL_MAIL = "WE_WILL_MAIL",
}

/**
 * An enumeration.
 */
export enum LeaseType {
  MARKET_RATE = "MARKET_RATE",
  NOT_SURE = "NOT_SURE",
  NO_LEASE = "NO_LEASE",
  NYCHA = "NYCHA",
  OTHER_AFFORDABLE = "OTHER_AFFORDABLE",
  RENT_CONTROLLED = "RENT_CONTROLLED",
  RENT_STABILIZED = "RENT_STABILIZED",
  RENT_STABILIZED_OR_CONTROLLED = "RENT_STABILIZED_OR_CONTROLLED",
}

/**
 * An enumeration.
 */
export enum LetterRequestMailChoice {
  USER_WILL_MAIL = "USER_WILL_MAIL",
  WE_WILL_MAIL = "WE_WILL_MAIL",
}

/**
 * An enumeration.
 */
export enum OnboardingInfoSignupIntent {
  EHP = "EHP",
  EVICTIONFREE = "EVICTIONFREE",
  HP = "HP",
  LALETTERBUILDER = "LALETTERBUILDER",
  LOC = "LOC",
  NORENT = "NORENT",
}

/**
 * An enumeration.
 */
export enum PhoneNumberAccountStatus {
  ACCOUNT_WITHOUT_PASSWORD = "ACCOUNT_WITHOUT_PASSWORD",
  ACCOUNT_WITH_PASSWORD = "ACCOUNT_WITH_PASSWORD",
  NO_ACCOUNT = "NO_ACCOUNT",
}

export interface AccessDatesInput {
  date1: string;
  date2: string;
  date3: string;
  clientMutationId?: string | null;
}

export interface AccessForInspectionInput {
  floorNumber: string;
  clientMutationId?: string | null;
}

export interface AgreeToTermsInput {
  agreeToTerms: boolean;
  site: string;
  clientMutationId?: string | null;
}

export interface BeginDocusignInput {
  nextUrl: string;
  clientMutationId?: string | null;
}

export interface CasesPriorCaseFormFormSetInput {
  caseNumber: string;
  caseDate: string;
  isHarassment: boolean;
  isRepairs: boolean;
  id?: string | null;
  DELETE: boolean;
}

export interface ChildrenTenantChildFormFormSetInput {
  name: string;
  dob: string;
  id?: string | null;
  DELETE: boolean;
}

export interface ClearAnonymousSessionInput {
  clientMutationId?: string | null;
}

export interface CustomHomeIssuesCustomIssueFormFormSetInput {
  description: string;
  id?: string | null;
  DELETE: boolean;
}

export interface CustomIssuesCustomIssueFormFormSetInput {
  description: string;
  id?: string | null;
  DELETE: boolean;
}

export interface EmailHpActionPdfInput {
  recipients: RecipientsEmailFormFormSetInput[];
  clientMutationId?: string | null;
}

export interface EmailLetterInput {
  recipients: RecipientsEmailFormFormSetInput[];
  clientMutationId?: string | null;
}

export interface EmergencyHPAIssuesInput {
  issues: string[];
  customHomeIssues: CustomHomeIssuesCustomIssueFormFormSetInput[];
  clientMutationId?: string | null;
}

export interface EvictionFreeAgreeToLegalTermsInput {
  compliesWithOtherLawfulTerms: boolean;
  understandsFinancialObligations: boolean;
  understandsProtectionIsTemporary: boolean;
  understandsRiskOfLandlordChallenge: boolean;
  clientMutationId?: string | null;
}

export interface EvictionFreeCovidImpactInput {
  hasFinancialHardship: boolean;
  hasHealthRisk: boolean;
  clientMutationId?: string | null;
}

export interface EvictionFreeCreateAccountInput {
  canWeSms: boolean;
  password: string;
  confirmPassword: string;
  agreeToTerms: boolean;
  clientMutationId?: string | null;
}

export interface EvictionFreeIndexNumberV2Input {
  indexNumber: string;
  courtName: string;
  hasCurrentCase: string;
  clientMutationId?: string | null;
}

export interface EvictionFreeSigningTruthfullyInput {
  isSigningTruthfully: boolean;
  clientMutationId?: string | null;
}

export interface EvictionFreeSubmitDeclarationInput {
  clientMutationId?: string | null;
}

export interface ExampleInput {
  exampleField: string;
  boolField: boolean;
  exampleOtherField: string;
  currencyField: string;
  subforms: SubformsExampleSubformFormSetInput[];
  clientMutationId?: string | null;
}

export interface ExampleRadioInput {
  radioField: string;
  clientMutationId?: string | null;
}

export interface FeeWaiverExpensesInput {
  rentAmount: string;
  expenseUtilities: string;
  expenseCable: string;
  expensePhone: string;
  expenseChildcare: string;
  expenseOther: string;
  clientMutationId?: string | null;
}

export interface FeeWaiverIncomeInput {
  incomeAmountMonthly: string;
  incomeSrcEmployment: boolean;
  incomeSrcHra: boolean;
  incomeSrcChildSupport: boolean;
  incomeSrcAlimony: boolean;
  incomeSrcSocialSecurity: boolean;
  incomeSrcOther: string;
  clientMutationId?: string | null;
}

export interface FeeWaiverMiscInput {
  askedBefore: string;
  clientMutationId?: string | null;
}

export interface FeeWaiverPublicAssistanceInput {
  receivesPublicAssistance: string;
  clientMutationId?: string | null;
}

export interface GenerateHpActionPdfInput {
  kind: string;
  clientMutationId?: string | null;
}

export interface HPActionPreviousAttemptsInput {
  filedWith311: string;
  thirtyDaysSince311: string;
  hpdIssuedViolations: string;
  thirtyDaysSinceViolations: string;
  clientMutationId?: string | null;
}

export interface HPActionSueInput {
  sueForRepairs: boolean;
  sueForHarassment: boolean;
  clientMutationId?: string | null;
}

export interface HPActionUrgentAndDangerousInput {
  urgentAndDangerous: string;
  clientMutationId?: string | null;
}

export interface HarassmentAllegations1Input {
  allegForce: boolean;
  allegMisleadingInfo: boolean;
  allegStoppedService: boolean;
  allegFailedToComply: boolean;
  allegFalseCertRepairs: boolean;
  allegConductInViolation: boolean;
  allegSued: boolean;
  clientMutationId?: string | null;
}

export interface HarassmentAllegations2Input {
  allegRemovedPossessions: boolean;
  allegInducedLeaving: boolean;
  allegContact: boolean;
  allegThreatsReStatus: boolean;
  allegRequestedId: boolean;
  allegDisturbed: boolean;
  clientMutationId?: string | null;
}

export interface HarassmentApartmentInput {
  twoOrLessApartmentsInBuilding: string;
  moreThanOneFamilyPerApartment: string;
  clientMutationId?: string | null;
}

export interface HarassmentExplainInput {
  harassmentDetails: string;
  clientMutationId?: string | null;
}

export interface HpaLandlordInfoInput {
  useRecommended: boolean;
  useMgmtCo: boolean;
  landlord: LandlordLandlordDetailsFormFormSetInput[];
  mgmtCo: MgmtCoManagementCompanyDetailsFormFormSetInput[];
  clientMutationId?: string | null;
}

export interface IssueAreaV2Input {
  area: string;
  issues: string[];
  customIssues: CustomIssuesCustomIssueFormFormSetInput[];
  clientMutationId?: string | null;
}

export interface LaLetterBuilderCreateAccountInput {
  canWeSms: boolean;
  password: string;
  confirmPassword: string;
  agreeToTerms: boolean;
  email: string;
  clientMutationId?: string | null;
}

export interface LaLetterBuilderCreateLetterInput {
  clientMutationId?: string | null;
}

export interface LaLetterBuilderDownloadPDFInput {
  letterId: string;
  clientMutationId?: string | null;
}

export interface LaLetterBuilderIssuesInput {
  laIssues: string[];
  clientMutationId?: string | null;
}

export interface LaLetterBuilderSendLetterInput {
  clientMutationId?: string | null;
}

export interface LaLetterBuilderSendOptionsInput {
  email: string;
  mailChoice: string;
  noLandlordEmail: boolean;
  clientMutationId?: string | null;
}

export interface LandlordDetailsV2Input {
  name: string;
  primaryLine: string;
  city: string;
  state: string;
  zipCode: string;
  clientMutationId?: string | null;
}

export interface LandlordLandlordDetailsFormFormSetInput {
  name: string;
  primaryLine: string;
  city: string;
  state: string;
  zipCode: string;
  id?: string | null;
}

export interface LandlordNameAddressInput {
  name: string;
  primaryLine: string;
  city: string;
  state: string;
  zipCode: string;
  clientMutationId?: string | null;
}

export interface LeaseTypeInput {
  leaseType: string;
  clientMutationId?: string | null;
}

export interface LetterRequestInput {
  mailChoice: string;
  clientMutationId?: string | null;
}

export interface LocLandlordInfoInput {
  useRecommended: boolean;
  landlord: LandlordLandlordDetailsFormFormSetInput[];
  clientMutationId?: string | null;
}

export interface LoginInput {
  phoneNumber: string;
  password: string;
  clientMutationId?: string | null;
}

export interface LogoutInput {
  clientMutationId?: string | null;
}

export interface MgmtCoManagementCompanyDetailsFormFormSetInput {
  name: string;
  primaryLine: string;
  city: string;
  state: string;
  zipCode: string;
  id?: string | null;
}

export interface NorentCityStateInput {
  city: string;
  state: string;
  clientMutationId?: string | null;
}

export interface NorentCreateAccountInput {
  canWeSms: boolean;
  password: string;
  confirmPassword: string;
  agreeToTerms: boolean;
  clientMutationId?: string | null;
}

export interface NorentEmailInput {
  email: string;
  clientMutationId?: string | null;
}

export interface NorentFullLegalAndPreferredNameInput {
  firstName: string;
  lastName: string;
  preferredFirstName: string;
  clientMutationId?: string | null;
}

export interface NorentFullLegalNameInput {
  firstName: string;
  lastName: string;
  clientMutationId?: string | null;
}

export interface NorentLandlordNameAndContactTypesInput {
  name: string;
  hasEmailAddress: boolean;
  hasMailingAddress: boolean;
  clientMutationId?: string | null;
}

export interface NorentNationalAddressInput {
  aptNumber: string;
  noAptNumber: boolean;
  street: string;
  zipCode: string;
  clientMutationId?: string | null;
}

export interface NorentOptInToRttcCommsInput {
  optIn: boolean;
  clientMutationId?: string | null;
}

export interface NorentOptInToSajeCommsInput {
  optIn: boolean;
  clientMutationId?: string | null;
}

export interface NorentOptionalEmailInput {
  email: string;
  clientMutationId?: string | null;
}

export interface NorentPreferredNameInput {
  preferredFirstName: string;
  clientMutationId?: string | null;
}

export interface NorentSendLetterV2Input {
  clientMutationId?: string | null;
}

export interface NorentSetUpcomingLetterRentPeriodsInput {
  rentPeriods: string[];
  clientMutationId?: string | null;
}

export interface NycAddressInput {
  address: string;
  borough: string;
  aptNumber: string;
  noAptNumber: boolean;
  clientMutationId?: string | null;
}

export interface OnboardingStep1V2Input {
  address: string;
  borough: string;
  aptNumber: string;
  noAptNumber: boolean;
  firstName: string;
  lastName: string;
  preferredFirstName: string;
  clientMutationId?: string | null;
}

export interface OnboardingStep3Input {
  leaseType: string;
  receivesPublicAssistance: string;
  clientMutationId?: string | null;
}

export interface OnboardingStep4Version2Input {
  canWeSms: boolean;
  signupIntent: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  agreeToTerms: boolean;
  clientMutationId?: string | null;
}

export interface OnboardingStep4WithOptionalEmailInput {
  canWeSms: boolean;
  signupIntent: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  agreeToTerms: boolean;
  clientMutationId?: string | null;
}

export interface OptionalLandlordDetailsInput {
  email: string;
  phoneNumber: string;
  clientMutationId?: string | null;
}

export interface PasswordResetConfirmAndLoginInput {
  password: string;
  confirmPassword: string;
  clientMutationId?: string | null;
}

export interface PasswordResetConfirmInput {
  password: string;
  confirmPassword: string;
  clientMutationId?: string | null;
}

export interface PasswordResetInput {
  phoneNumber: string;
  clientMutationId?: string | null;
}

export interface PasswordResetVerificationCodeInput {
  code: string;
  clientMutationId?: string | null;
}

export interface PhoneNumberInput {
  phoneNumber: string;
  clientMutationId?: string | null;
}

export interface PriorHPActionCasesInput {
  cases: CasesPriorCaseFormFormSetInput[];
  clientMutationId?: string | null;
}

export interface PublicAssistanceInput {
  receivesPublicAssistance: string;
  clientMutationId?: string | null;
}

export interface QueryOrVerifyPhoneNumberInput {
  phoneNumber: string;
  clientMutationId?: string | null;
}

export interface RecipientsEmailFormFormSetInput {
  email: string;
}

export interface ReliefAttemptsInput {
  hasCalled311: string;
  clientMutationId?: string | null;
}

export interface RhFormInput {
  firstName: string;
  lastName: string;
  apartmentNumber: string;
  address: string;
  borough: string;
  phoneNumber: string;
  clientMutationId?: string | null;
}

export interface RhSendEmailInput {
  clientMutationId?: string | null;
}

export interface SendVerificationEmailInput {
  email: string;
  clientMutationId?: string | null;
}

export interface SubformsExampleSubformFormSetInput {
  exampleField: string;
}

export interface TenantChildrenInput {
  children: ChildrenTenantChildFormFormSetInput[];
  clientMutationId?: string | null;
}

export interface TicketNumbersTicketNumberFormFormSetInput {
  ticketNumber: string;
}

export interface UnimpersonateInput {
  clientMutationId?: string | null;
}

export interface WorkOrderTicketsInput {
  noTicket: boolean;
  ticketNumbers: TicketNumbersTicketNumberFormFormSetInput[];
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
